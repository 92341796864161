import React from 'react'
import styled from 'styled-components'
import useActiveWeb3React from '../../hooks/useActiveWeb3React'

const ChainLogo:React.FC = ()=>{
  const {chainId} = useActiveWeb3React()
  const handleLogo = ()=>{
    if(chainId===97||chainId===56){
      return (
        <img src="/images/chainIcon/56.png" alt="binance"/>
      )
    }
    if(chainId===4||chainId===1){
      return (
        <img src="/images/chainIcon/ethereum-logo.png" alt="ethereum"/>
      )
    }
    if(chainId===80001||chainId===137){
      return (
        <img src="/images/chainIcon/polygon-logo.png" alt="polygon"/>
      )
    }
    if(chainId===43113||chainId===43114){
      return (
        <img src="/images/chainIcon/avax-logo.png" alt="avax"/>
      )
    }
    if(chainId===321||chainId===322){
      return (
        <img src="/images/chainIcon/kcc-logo.png" alt="kcc"/>
      )
    }
    if(chainId===66||chainId===65){
      return (
        <img src="/images/chainIcon/oec-logo.png" alt="oec"/>
      )
    }
    if(chainId===250||chainId===4002){
      return (
        <img src="/images/chainIcon/ftm-logo.png" alt="ftm"/>
      )
    }
    if(chainId===86||chainId===85){
      return (
        <img src="/images/chainIcon/ftm-logo.png" alt="gate"/>
      )
    }
    if(chainId===128||chainId===256){
      return (
        <img src="/images/chainIcon/ftm-logo.png" alt="heco"/>
      )
    }
    return null
  }
  return (
    <LogoBox>
      {handleLogo()}
    </LogoBox>
  )
}

const LogoBox = styled.div`
  width: 20px;
  height: 20px;
  & img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
export default ChainLogo

interface nameLogoProps {
  cId?: number
  url?: string
}
export const ChainNameLogo:React.FC<nameLogoProps> = ({cId,url})=>{
  const {chainId} = useActiveWeb3React()
  const curChainId = cId??chainId
  const handleLogo = ()=>{
    if(curChainId===97||curChainId===56){
      return (
        <img src="/images/chainIcon/binance.png" alt="binance"/>
      )
    }
    if(curChainId===4||curChainId===1){
      return (
        <img src="/images/chainIcon/ethereum.png" alt="ethereum"/>
      )
    }
    if(curChainId===80001||curChainId===137){
      return (
        <img src="/images/chainIcon/polygon.png" alt="polygon"/>
      )
    }
    if(curChainId===43113||curChainId===43114){
      return (
        <img src="/images/chainIcon/avax.png" alt="avax"/>
      )
    }
    if(curChainId===128||curChainId===256){
      return (
        <img src="/images/chainIcon/HuobiEcoChain.png" alt="heco"/>
      )
    }
    if(curChainId===85||curChainId===86){
      return (
        <img src="/images/chainIcon/GateChain.png" alt="gatechain"/>
      )
    }

    if(curChainId===321||curChainId===322){
      return (
        <img src="/images/chainIcon/KCC.png" alt="kcc"/>
      )
    }
    if(curChainId===66||curChainId===65){
      return (
        <img src="/images/chainIcon/OKC.png" alt="okc"/>
      )
    }
    if(curChainId===250||curChainId===4002){
      return (
        <img src="/images/chainIcon/fantom.png" alt="ftm"/>
      )
    }
    return null
  }
  return url?(
    <a href={url} target="_self" rel="noreferrer">
      <NameLogoBox>
        {handleLogo()}
      </NameLogoBox>
    </a>
  ):(
    <NameLogoBox>
      {handleLogo()}
    </NameLogoBox>
  )
}

const NameLogoBox = styled.div`
  height: 24px;
  width: 80px;
  border-radius: ${({theme})=>theme.expandColor.radius.radius3};
  border: 2px solid ${({theme})=>theme.expandColor.color.active};
  padding: 10px;
  box-sizing: content-box;
  cursor: pointer;
  margin: 5px 0;
  & img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`
